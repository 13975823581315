import styled from "styled-components";

const DV = styled.div`
    position: relative;
    @media (max-width: 929px) {
        height: 42vw;
        min-width: 30vw;
        max-height: 300px;
        margin-right: 24px;
        margin-left: -12px;
        margin-bottom: 20px;
    }
    @media (min-width: 930px) {
        min-height: 100%;
        min-width: 250px;
        margin-right: 80px;
    }
    background: url('/assets/img/dv.jpg') no-repeat;
    background-size: contain;
`;

const P = styled.p`
    @media (max-width: 929px) {
        margin: 14px 0 14px 0;
    }
    @media (min-width: 930px) {
        margin: 20px 0 20px 0;
    }
`;

const CONTAINER = styled.div`
    display: flex;
    @media (max-width: 929px) {
        flex-wrap: wrap;
        margin: 24px 0 35px 0;
    }
    @media (min-width: 930px) {
        margin: 35px 0 35px 0;
    }
`;

const BUTTONS = styled.div`
    width: 20px;
    @media (min-width: 930px) {
        height: 150px;
    }
`;

const BUTTON = styled.img`
    width: 20px;
    height: 20px;
`;

const PROJECT = styled.div`
    box-sizing: border-box;
    background: rgba(255, 250, 248, 0.1);
    @media (max-width: 929px) {
        width: calc(100vw - 60px);
        max-width: 600px;
    }
    @media (min-width: 930px) {
        min-width: 300px;
    }
    padding: 15px;
    margin-left: 10px;
`;

const GALLERY = styled.div`
    @media (max-width: 929px) {
        display: flex;
        width: calc(100vw - 60px);
        max-width: 600px;
        margin-left: 30px;
    }
    @media (min-width: 930px) {
        height: 150px;
        min-width: 600px;
    }
`;

const GIMG = styled.img`
    @media (max-width: 929px) {
        min-width: 75px;
        min-height: 75px;
    }
    @media (min-width: 930px) {
        width: 150px;
        height: 150px;
    }
`;

const HR = styled.hr`
    height: 5px;
    opacity: 0;
`;

export { DV, P, PROJECT, GALLERY, GIMG, CONTAINER, BUTTONS, BUTTON, HR };