import React from "react";
import { useMediaQuery } from "react-responsive";
import { DV, P, PROJECT, GALLERY, GIMG, CONTAINER, BUTTONS, BUTTON, HR } from "./Components";
import "./animations.scss";
//import { LICENSES_AND_TERMS } from "./terms";

export default function Home() {
  const isMobile = useMediaQuery({ query: "(max-width: 929px)" });
  const isLong = useMediaQuery({ query: "(min-height: 851px)" });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: isMobile ? {} : "center"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: isMobile || !isLong ? "flex-start" : "center",
          padding: isMobile ? 12 : 36,
          boxSizing: "border-box",
          height: "100vh"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end"
          }}
        >
          <DV />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start"
            }}
          >
            <P
              className="Helvetica font-med"
              data-aos-anchor=".body"
              data-aos="fade-in"
              data-aos-duration="400"
            >
              hi, i&#8217;m DV.
            </P>
            <P
              className="Helvetica font-sm"
              data-aos="fade-in"
              data-aos-delay="50"
              data-aos-duration="400"
            >
              i build software tools to write with AI.
            </P>
            <P
              className="Helvetica font-sm"
              data-aos="fade-in"
              data-aos-delay="100"
              data-aos-duration="400"
            >
              i&#8217;ve been a beta tester and early adopter of AI models like GPT-2, <a href="https://openai.com/api/" target="_blank" rel="noreferrer">GPT-3</a>, <a href="https://openai.com/dall-e-2/" target="_blank" rel="noreferrer">DALL·E 2</a>, <a href="https://www.midjourney.com" target="_blank" rel="noreferrer">Midjourney</a>, and <a href="https://github.com/CompVis/stable-diffusion" target="_blank" rel="noreferrer">Stable Diffusion</a>.
            </P>
            <P
              className="Helvetica font-sm"
              data-aos="fade-in"
              data-aos-delay="150"
              data-aos-duration="400"
            >
              i&#8217;m also co-founder of a web3 creative studio, <a href="https://hexcodecrew.com">Hex Code Crew</a>.
            </P>
            <P
              className="Helvetica font-sm"
              data-aos-anchor=".body"
              data-aos="fade-in"
              data-aos-delay="250"
              data-aos-duration="400"
            >
              questions? contact me on <a href="https://twitter.com/dudevision">Twitter</a>.
            </P>
          </div></div>
        <HR />
        <P
          className="Helvetica font-sm"
          data-aos-anchor=".body"
          data-aos="half-opacity"
          data-aos-delay="400"
          data-aos-duration="400"
        >
          in progress:
        </P>
        <CONTAINER
          data-aos-anchor=".body"
          data-aos="half-opacity"
          data-aos-delay="600"
          data-aos-duration="400">
          <BUTTONS>
            <a href="https://starnomads.com">
              <BUTTON src="assets/img/external-link.svg" />
            </a>
            <a href="https://twitter.com/starnomadsNFT">
              <BUTTON src="assets/img/twitter.svg" />
            </a>
            <a href="https://discord.gg/starnomads">
              <BUTTON src="assets/img/discord.svg" />
            </a>
          </BUTTONS>
          <PROJECT
            className="Helvetica font-sm"
          >
            <span class="italic">Star Nomads</span><br />with Hex Code Crew<br />10,000 pcs<br />human art<br />modified dynamic prompt GPT-3
            <br />4096x4096 jpeg&nbsp;&nbsp;erc721a&nbsp;&nbsp;ipfs
          </PROJECT>
          <GALLERY>
            <GIMG src="assets/img/nomad0.jpg" />
            <GIMG src="assets/img/nomad1.jpg" />
            <GIMG src="assets/img/nomad2.jpg" />
            <GIMG src="assets/img/nomad3.jpg" />
          </GALLERY>
        </CONTAINER>
        {isMobile ? <></> : <HR />}
        <P
          className="Helvetica font-sm footer"
          data-aos-anchor=".body"
          data-aos="fade-in"
          data-aos-delay="700"
          data-aos-duration="400"
        >
          &copy;{new Date().getFullYear()} dudevision{/*&nbsp;&nbsp;&nbsp;<LINK id="terms">all licenses & terms</LINK>*/}
        </P>
      </div>
    </div>
  );
}
